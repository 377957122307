// component

import { FaChartBar, FaRegListAlt } from 'react-icons/fa';
import { BsFillClipboard2Fill } from 'react-icons/bs';

import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'School Approval',
    path: '/dashboard/approval',
    icon: icon('ic_user'),
    // Show for all users
    visible: 'CLIENT',
  },
  {
    title: 'Dashboard',
    path: '/dashboard/report',
    icon: icon('ic_user'),
    // Show for all users icon: <FaRegListAlt />,
    visible: 'TEACHER',
  },
  {
    title: 'studentList',
    path: '/dashboard/studentList',
    icon: <FaRegListAlt />,
    // Show for all users
    visible: 'TEACHER',
  },

  // {
  //   title: 'Analytics',
  //   path: '/dashboard/analytics',
  //   icon: <BsFillClipboard2Fill />,
  //   // Show for all users
  //   visible: 'TEACHER',
  // },
  {
    title: 'Leaderboard & Reports',
    path: '/dashboard/leaderboard',
    icon: <FaChartBar />,
    // Show for all users
    visible: 'TEACHER',
  },
  // {
  //   title: 'Add School Admin',
  //   path: '/dashboard/addschooldmin',
  //   icon: <BsFillClipboard2Fill />,
  //   // Show for all users
  //   visible: 'CLIENT',
  // },
];

export function getNavConfig() {
  const userRole = localStorage.getItem('role');
  return navConfig.filter((item) => item.visible === userRole);
}
