import {
  REGISTRATION_ENDPOINT,
  GET_SCHOOL,
  GET_STUDENT_LIST,
  EDIT_STUDENT,
  GET_REPORTS,
  GET_ANALYTICS,
  LEADER_BOARD,
  APPROVE_TEACHER_LIST,
  APPROVE_TEACHER,
  DELETE_TEACHER,
  SAVE_STUDENT_DETAILS,
  REMOVE_SCHOOL_STUDENT,
  GET_PROGRESS_REPORT,
  PERSONAL_REPORT,
  STUDENT_LISTDOWNLOAD,
  DOWNLOAD_USER_SUB_DETAIL,
  GET_SUBJECT_DROPDOWN,
} from '../Api/URLS';
import api from '../Api/api';

const registerTeacher = (payload) => {
  return api.post(REGISTRATION_ENDPOINT, payload).then((response) => {
    return response;
  });
};

const getExcel = (userId, subjectId, schoolName) => {
  return api
    .post(DOWNLOAD_USER_SUB_DETAIL, {
      userId,
      subjectId,
      schoolName,
    })
    .then((response) => {
      return response;
    });
};

const getShoolList = () => {
  return api.get(GET_SCHOOL).then((response) => {
    return response;
  });
};

const getTeachersList = () => {
  return api.get(APPROVE_TEACHER_LIST).then((response) => {
    return response;
  });
};

const getSubjectDropdown = () => {
  return api.get(GET_SUBJECT_DROPDOWN).then((response) => {
    return response;
  });
};

const getStudentLists = (school) => {
  return api.post(GET_STUDENT_LIST, { school }).then((response) => {
    return response;
  });
};

const getStudentFilters = (school, search, grade, div) => {
  return api.post(GET_STUDENT_LIST, { school, search, grade, div }).then((response) => {
    return response;
  });
};
const editStudents = (userId) => {
  return api.get(`${EDIT_STUDENT}?userId=${userId}`).then((response) => {
    return response;
  });
};

const getReportsApi = (data) => {
  return api.post(GET_REPORTS, data).then((response) => {
    return response;
  });
};
const getAnyalaticApi = (startDate, endDate, schoolName) => {
  return api.post(GET_ANALYTICS, { startDate, endDate, schoolName }).then((response) => {
    return response;
  });
};

const leaderBoardsApi = (schoolName) => {
  return api.post(LEADER_BOARD, { schoolName }).then((response) => {
    return response;
  });
};

const getLeaderFilters = (startDate, grade, name, schoolName, endDate) => {
  return api.post(LEADER_BOARD, { startDate, grade, name, schoolName, endDate }).then((response) => {
    return response;
  });
};

const getStudentReport = (userId, courseId) => {
  return api.post(GET_PROGRESS_REPORT, { userId, courseId }).then((response) => {
    return response;
  });
};

const getPersonalReport = (userId, subjectId, schoolName) => {
  return api.post(PERSONAL_REPORT, { userId, subjectId, schoolName }).then((response) => {
    return response;
  });
};

const approvedTeacher = (userId) => {
  return api.get(`${APPROVE_TEACHER}?userId=${userId}`).then((response) => {
    return response;
  });
};

const deleteTeacher = (userId) => {
  return api.get(`${DELETE_TEACHER}?userId=${userId}`).then((response) => {
    return response;
  });
};

const removeSchool = (userId) => {
  return api.get(`${REMOVE_SCHOOL_STUDENT}?userId=${userId}`).then((response) => {
    return response;
  });
};

const downloadStudentList = (school) => {
  return api.post(STUDENT_LISTDOWNLOAD, { school }, { responseType: 'blob' }).then((response) => {
    if (response?.status === 200 && response?.data) {
      return URL.createObjectURL(response?.data);
    }
    return null;
  });
};

const downloadStudentListExcel = (school) => {
  return api.post(STUDENT_LISTDOWNLOAD, { school }, { responseType: 'blob' }).then((response) => {
    if (response?.status === 200 && response?.data) {
      return response?.data;
    }
    return null;
  });
};

const submitEditData = (userId, studentName, grade, div) => {
  return api
    .post(SAVE_STUDENT_DETAILS, {
      userId,
      studentName,
      grade,
      div,
    })
    .then((response) => {
      // localStorage.setItem("role", "marketing");
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export {
  registerTeacher,
  getShoolList,
  getStudentLists,
  editStudents,
  getReportsApi,
  leaderBoardsApi,
  getTeachersList,
  approvedTeacher,
  deleteTeacher,
  getAnyalaticApi,
  submitEditData,
  removeSchool,
  getStudentReport,
  getPersonalReport,
  getStudentFilters,
  downloadStudentList,
  getExcel,
  getSubjectDropdown,
  getLeaderFilters,
  downloadStudentListExcel,
};
