import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import Loader from '../components/common/Loader';

import { getShoolList, registerTeacher } from '../Servies/AdmiServices';

const RegistrationPage = () => {
  const [selectedDropdown, setSelectedDropdown] = useState('registered'); // Default selected dropdown
  const [schoolDropdownVisible, setSchoolDropdownVisible] = useState(true);
  const [isSchoolApi, setIsSchoolApi] = useState(false);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [shoolvalue, setSchoolvalue] = useState([]);

  const [formData, setFormData] = useState({
    userName: '',
    password: '',
    email: '',
    countryCode: '',
    name: '',
    school: '',
    mobile: '',
  });

  useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    try {
      const response = await getShoolList();
      const schooldata = response?.data.map((data) => ({
        name: data?.name,
      }));

      setSchoolvalue(schooldata);
    } catch (error) {
      console.error('Error fetching schools:', error);
    }
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setIsSchoolApi(false);

    console.log('kunal', name);
  };

  const registeredSchools = ['School A', 'School B', 'School C'];

  // useEffect(() => {
  //   handleChangeSchool();
  // }, []);

  // const handleSchoolSelect = () => {
  //   const selectedSchool =
  // }

  const handlePlaceSelect = (place) => {
    const selectedSchool = place?.value?.description;
    console.log('selectedSchool', selectedSchool);
    setFormData({ ...formData, school: selectedSchool });
    setIsSchoolApi(true);
  };

  const handleRegister = () => {
    console.log(formData);
    const { countryCode, userName, school, ...otherFormData } = formData;
    const updatedUsername = `${countryCode}${userName}`;
    let selectedSchool;

    if (school && selectedDropdown === 'registered') {
      console.log(school);
      // School selected from the registered schools dropdown
      selectedSchool = school;
    } else if (school && selectedDropdown === 'Google') {
      console.log('google', school);
      // School selected from Google Places Autocomplete
      selectedSchool = school;
    }

    if (selectedSchool === '' && selectedDropdown === 'google') {
      console.log('hii');
      // Handle the case when a Google Places Autocomplete value is provided but school is not selected
      // You may show an error message or perform additional handling
    }
    setIsLoading(true);
    registerTeacher({
      ...otherFormData,
      // userName: updatedUsername,
      school: isSchoolApi ? selectedSchool : selectedSchool?.name,
      countryCode: formData?.countryCode,
      userName: formData?.countryCode + formData?.mobile,
      mobile: formData?.mobile,
    })
      .then((res) => {
        console.log(res?.data, res?.data?.message);
        console.log('data', res?.data);
        swal({
          title: 'User Registered Successfully!!!',
          text: 'Registered User will be under approval by admin kindly wait for further process',
          icon: 'success',
          success: true,
        });
        toast.success(res?.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log('data one', res?.data);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error in registration:', error);
      });
  };

  const handleEditEdit = () => {
    navigate(`/login`);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {isLoading && <Loader />}
      <LoadScript googleMapsApiKey="AIzaSyDXBN2pXCERFkX6zEVqAl8QJACWY08_X0w" libraries={['places']}>
        <Container>
          <br />
          <Card>
            <CardContent>
              <Typography component="h1" variant="h5">
                School Admin Registration
              </Typography>
              <div className="mb-4 d-flex">
                Already have an account?{' '}
                {/* <Link to="/login" className="fw-700">
          Log In Instead
        </Link> */}
                <Link
                  className="fw-700 text-underline ms-1 pointer color-blue"
                  style={{ cursor: 'pointer' }}
                  onClick={handleEditEdit}
                >
                  Log In Instead
                </Link>
              </div>

              {/* <img src="/logo-02 (1).png" height={50} style={{ marginLeft: '950px', marginTop: '-110px' }} alt="logo" /> */}
              <br />
              <form>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Name"
                      name="name"
                      autoComplete="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">
                      //       <AccountCircle />
                      //     </InputAdornment>
                      //   ),
                      // }}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <AccountCircle />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </Grid> */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Password"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      value={formData.password}
                      onChange={handleInputChange}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">
                      //       <LockOutlined />
                      //     </InputAdornment>
                      //   ),
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Email"
                      name="email"
                      type="email"
                      autoComplete="new-email"
                      value={formData.email}
                      onChange={handleInputChange}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">
                      //       <LockOutlined />
                      //     </InputAdornment>
                      //   ),
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="country-code-label">Country</InputLabel>
                      <Select
                        name="countryCode"
                        labelId="country-code-label"
                        id="country-code-select"
                        value={formData?.countryCode || ''}
                        onChange={handleInputChange}
                        label="Country"
                      >
                        <MenuItem value={''}>Select</MenuItem>
                        <MenuItem value={'+91'}>+91</MenuItem>
                        <MenuItem value={'+974'}>+974</MenuItem>
                        <MenuItem value={'+971'}>+971</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="MobileNo"
                      name="mobile"
                      autoComplete="New-userName"
                      value={formData.mobile}
                      onChange={handleInputChange}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">
                      //       <AccountCircle />
                      //     </InputAdornment>
                      //   ),
                      // }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel id="school-label">School</InputLabel>
                    <Select
                      fullWidth
                      name="school"
                      value={formData?.school}
                      onChange={(event) => {
                        handleInputChange(event);
                      }}
                      label="School"
                      variant="outlined"
                    >
                      <MenuItem value="">
                        <em>Select School</em>
                      </MenuItem>
                      {shoolvalue?.map((schools, index) => (
                        <MenuItem key={index} value={schools}>
                          {schools.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <div>Search School if not present in the dropdown </div>
                  </Grid>
                  {schoolDropdownVisible && (
                    <Grid item xs={12}>
                      {/* <GooglePlacesAutocomplete
                      apiKey="AIzaSyDXBN2pXCERFkX6zEVqAl8QJACWY08_X0w"
                      onSelect={handlePlaceSelect}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: 'Search for a school...',
                            })}
                          />
                          <div>
                            {loading && <div>Loading...</div>}

                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                              };
                              return (
                                <div {...getSuggestionItemProps(suggestion, { style })}>{suggestion.description}</div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </GooglePlacesAutocomplete> */}
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyDXBN2pXCERFkX6zEVqAl8QJACWY08_X0w"
                        selectProps={{
                          value: formData?.school, // Get the current value
                          onChange: (event) => {
                            handlePlaceSelect(event);
                          },
                        }}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: 'Search for a school...',
                              })}
                            />
                            <div>
                              {loading && <div>Loading...</div>}

                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                                };
                                return (
                                  <div {...getSuggestionItemProps(suggestion, { style })}>{suggestion.description}</div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </GooglePlacesAutocomplete>
                    </Grid>
                  )}
                </Grid>
                <Button fullWidth variant="contained" color="primary" sx={{ marginTop: 2 }} onClick={handleRegister}>
                  Register
                </Button>
              </form>
            </CardContent>
          </Card>
        </Container>
      </LoadScript>
    </>
  );
};

export default RegistrationPage;
