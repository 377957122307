import { Helmet } from 'react-helmet-async';

import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, TextField, alertTitleClasses } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLocation, useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaClipboardList } from 'react-icons/fa';
import { IconName } from 'react-icons/ai';
import { GENERAL_ERROR_MESSAGE } from '../utils/message';

import Iconify from '../components/iconify';
import { getTeachersList, approvedTeacher, deleteTeacher } from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';

export default function StudentsList() {
  const location = useLocation();
  // const userId = new URLSearchParams(location.search).get('userId');

  const navigate = useNavigate();
  const [approvalList, setApprovalList] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const headers = ['Name', 'Phone Number', 'School Name', 'Email', 'Requested On', 'Action'];
  const handleNewUserClick = () => {
    navigate('/dashboard/CreateClient');
  };
  const handleEditEdit = () => {
    navigate(`/dashboard/edit`);
  };
  // const student = [(Name = 'kunal'), (Grade = 'A'), (Div = 'B'), (LastActivityDate = '9 jan 2023')];

  const fetchAllData = () => {
    setIsLoading(true);

    getTeachersList()
      .then((res) => {
        setApprovalList(res?.data);
        setIsLoading(false);

        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching Admin data:', error);
      });
  };

  console.log(approvalList);
  useEffect(() => {
    fetchAllData();
  }, []);

  const handleApprovalData = (userId) => {
    // alert('hiii');
    setIsLoading(true);

    approvedTeacher(userId)
      .then((res) => {
        toast.success(res?.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching course data:', error);
      });
  };

  const handleDeleteSchedule = (userId) => {
    setIsLoading(true);
    deleteTeacher(userId)
      .then((res) => {
        console.log('check delete', res?.data);
        toast.success(res?.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
        fetchAllData();
      })
      .catch((error) => {
        setIsLoading(false);

        console.error('Error fetching course data:', error);
      });
  };
  // const teacher = [
  //   { Name: 'Student 1', PhoneNumber: 85, SchoolName: 90, Email: 78, RequestedOn: 92, score5: 88 },
  //   { Name: 'Student 2', PhoneNumber: 78, SchoolName: 92, Email: 88, RequestedOn: 95, score5: 89 },
  // ];

  const specificColumns = Array.isArray(approvalList)
    ? approvalList.map((item) => {
        return {
          Name: item?.name,
          'Phone Number': item?.phoneNumber,
          'School Name': item.schoolName,
          Email: item.email,
          'Requested On': item.requestedOn,
          Action: (
            <div>
              <Button
                variant="contained"
                startIcon={<Iconify icon="mdi:approve" />}
                onClick={() => handleApprovalData(item.userId)}
              >
                Approve
              </Button>
              <FaTrash
                style={{ cursor: 'pointer', marginLeft: '150px' }}
                onClick={() => handleDeleteSchedule(item.userId)}
                // onClick={() => handleDeleteUser(item.userId)}
              />
            </div>
          ),
        };
      })
    : [];

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {isLoading && <Loader />}

      <Helmet>
        <title> School Admin </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" alignItems="center" gutterBottom>
            School Admin Platform - Approve Admin Request
          </Typography>
          {/* <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            // onClick={
            //   Navigate('/dashboard/app/manageuser')
            //   }>
            onClick={handleNewUserClick}
          >
            Create Client
          </Button> */}
        </Stack>

        <br />

        <TableData data={specificColumns} headers={headers} rowsPerPageOptions={[5, 10, 20]} />
        <br />
      </Container>
    </>
  );
}
