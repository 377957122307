import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
  TextField,
  Box,
  Stack,
  Typography,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Tooltip,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { leaderBoardsApi, getLeaderFilters } from '../Servies/AdmiServices';
import Loader from '../components/common/Loader';

import Iconify from '../components/iconify';

export default function Leaderboard() {
  const [selectedDate, setSelectedDate] = useState(''); // State for the selected date
  const [selectedendDate, setSelectedendDate] = useState('');
  const [leaderdata, setLeaderData] = useState([]);
  const schoolName = localStorage.getItem('schoolName');
  const [isLoading, setIsLoading] = useState(false);
  const [grade, setGrade] = useState('');
  const headers = [
    'Name',
    'Grade',
    'Topic Viewed',
    'Activity check',
    'Assessment(MCQ)',
    'Growth Score',
    'Score',
    'Action',
  ];

  const [name, setName] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Perform any actions you want when the date is changed
  };

  const handlenameChange = (event) => {
    setName(event.target.value);
  };

  const handleChange = (event) => {
    setGrade(event.target.value);
  };

  const tableData = [
    // {
    //   id: 1,
    //   name: 'Student 1',
    //   score1: 85,
    //   score2: 90,
    //   score3: 78,
    //   score4: 92,
    //   score5: 88,
    //   date: new Date('2023-10-01'),
    // },
    // {
    //   id: 2,
    //   name: 'Student 2',
    //   score1: 78,
    //   score2: 92,
    //   score3: 88,
    //   score4: 95,
    //   score5: 89,
    //   date: new Date('2023-10-05'),
    // },
    // {
    //   name: '??????.???s???. P??s??',
    //   grade: '6',
    //   topicViewed: 0,
    //   growthScore: 0,
    //   score: 0,
    //   userId: '930fe1d4-51e8-4b6d-b7f6-670f93dac3ea',
    // },
    // Add more student data as needed
  ];

  const handlesubmits = () => {
    setIsLoading(true);
    console.log(leaderdata);
    leaderBoardsApi(schoolName)
      .then((res) => {
        console.log(res?.data, res?.data?.message);
        console.log('data', res?.data);

        setLeaderData(res?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Update leader error:', error);
      });
  };
  console.log(leaderdata);

  useEffect(() => {
    handlesubmits();
  }, []);

  const handleserach = () => {
    setIsLoading(true);
    console.log(leaderdata);
    getLeaderFilters(selectedDate, grade, name, schoolName, selectedendDate)
      .then((res) => {
        if (res?.data && res.data.length > 0) {
          setLeaderData(res?.data || []);
          setIsLoading(false);
        } else {
          // Handle empty response data, for example:
          console.log('No data available');
          // You can set a message or handle it in any other way appropriate for your application
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Update leader error:', error);
      });
  };
  console.log('data', leaderdata);

  return (
    <>
      {isLoading && <Loader />}

      {/* <Helmet>
        <title>Leaderboard</title>
      </Helmet> */}
      <Container>
        <Helmet>
          <title>Leaderboard</title>
        </Helmet>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              School Admin Platform - Leaderboard & Report
            </Typography>
          </Stack>
          <Container>
            <Box display="flex" justifyContent="space-between" marginBottom={2}>
              <Stack spacing={2} direction="row" paddingLeft={2}>
                <TextField
                  label="From Date"
                  type="date"
                  value={selectedDate} // Format date as "YYYY-MM-DD"
                  onChange={(e) => handleDateChange(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="To Date"
                  type="date"
                  value={selectedendDate} // Format date as "YYYY-MM-DD"
                  onChange={(e) => setSelectedendDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {/* <Button
                  variant="contained"
                  startIcon={<Iconify icon="formkit:submit" />}
                  sx={{ marginLeft: '899px', height: '50px' }}
                  onClick={handleserach}
                >
                  Submit
                </Button> */}
                <br />
              </Stack>
              {/* <TextField label="Grade Filter" type="name" sx={{ left: '100px' }} onChange={handleChange} /> */}
              <FormControl sx={{ minWidth: 80, right: '100px' }}>
                <InputLabel id="demo-simple-select-autowidth-label">Grade</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={grade}
                  onChange={handleChange}
                  autoWidth
                  label="Grade"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </Select>
              </FormControl>
              <TextField
                name="Name"
                label="Name"
                style={{ width: '170px', right: '185px' }}
                onChange={handlenameChange}
              />
              <Button
                variant="contained"
                sx={{ right: '260px' }}
                startIcon={<Iconify icon="formkit:submit" />}
                onClick={handleserach}
              >
                Search
              </Button>
            </Box>
            {leaderdata.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Grade</TableCell>
                    <TableCell>Topic Viewed</TableCell>
                    <TableCell>Activity check</TableCell>
                    <TableCell>Assessment(MCQ) Score</TableCell>
                    <TableCell>Growth Score</TableCell>
                    <TableCell>Score</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {/* <TableBody>
              {leaderdata.length>0  && leaderdata.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link component={RouterLink} to={`/dashboard/manageleader`} color="primary">
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell>{row.grade}</TableCell>
                  <TableCell>{row.topicViewed}</TableCell>
                  <TableCell>{row.assessment}</TableCell>
                  <TableCell>{row.growthScore}</TableCell>
                  <TableCell>{row.score}</TableCell>
                </TableRow>
              ))}


              
            </TableBody> */}

                <TableBody>
                  {leaderdata.length > 0 &&
                    leaderdata.map((row) => (
                      <TableRow key={row.id}>
                        <Tooltip title="View student Report">
                          <TableCell>
                            {/* `/dashboard/CourseStructure?courseId=${courseId}` */}
                            <Link
                              component={RouterLink}
                              to={`/dashboard/manageleader?userId=${row.userId}&courseId=${row.courseId}`}
                              color="primary"
                            >
                              {row.name}
                            </Link>
                          </TableCell>
                        </Tooltip>
                        <TableCell>{row.grade}</TableCell>
                        <TableCell>{row.topicViewed}</TableCell>
                        <TableCell>{row.activityCheck}</TableCell>
                        <TableCell>{row.assessmentScore}</TableCell>
                        <TableCell>{row.growthScore}</TableCell>
                        <TableCell>{row.score}</TableCell>
                        <Tooltip title="View student Report">
                          <TableCell>
                            <Link
                              component={RouterLink}
                              to={`/dashboard/manageleader?userId=${row.userId}&courseId=${row.courseId}`}
                              color="primary"
                            >
                              {row.action}
                            </Link>
                          </TableCell>
                        </Tooltip>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              'NO Data Available'
            )}
          </Container>
        </Container>
      </Container>
    </>
  );
}
