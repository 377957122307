import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components

// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

// const StyledRoot = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex',
//   },
// }));

// const StyledSection = styled('div')(({ theme }) => ({
//   width: '100%',
//   maxWidth: 480,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   boxShadow: theme.customShadows.card,
//   backgroundColor: theme.palette.background.default,
// }));

// const StyledContent = styled('div')(({ theme }) => ({
//   maxWidth: 480,
//   margin: 'auto',
//   minHeight: '100vh',
//   display: 'flex',
//   justifyContent: 'center',
//   flexDirection: 'column',
//   padding: theme.spacing(12, 0),
// }));


const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
  },
  
}));

const Section = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 580,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  margin: 'auto',
  padding: theme.spacing(5),
}));

const Content = styled('div')({
  maxWidth: 580,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '5rem',
});
// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <LoginForm />
    // <>
    //   <Helmet>
    //     <title>Login</title>
    //   </Helmet>

    //   <StyledRoot>
    //     {/* <Logo /> */}

    //     {mdUp && (
    //       <Section>
    //         <Typography variant="h3" sx={{ mb: 2 }}>
    //           Teacher’s Administration Portal
    //         </Typography>
    //         <Typography variant="h5" color="textSecondary" gutterBottom>
    //           This platform provides access to teachers from registered schools to administer student performances.
    //         </Typography>
    //       </Section>
    //     )}

    //     <Container maxWidth="sm">
    //       <Content>
    //         <Typography variant="h4" gutterBottom>
    //           Sign In to Your Account
    //         </Typography>

    //         <LoginForm />
    //       </Content>
    //     </Container>
    //   </StyledRoot>
    // </>
  );
        }
