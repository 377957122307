import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import ThemeProvider from './theme';
import Router from './routes';
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';

export default function App() {
  return (
    <HelmetProvider>
      {/* <ReportProvider> */}
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router />
        </ThemeProvider>
      </BrowserRouter>
      {/* </ReportProvider> */}
    </HelmetProvider>
  );
}
