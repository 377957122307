import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  CircularProgress,
  Stack,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Helmet } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { editStudents, submitEditData } from '../Servies/AdmiServices';

import Loader from '../components/common/Loader';
// import { getAllBoardDetails, editCourseDetail, getpublisher, AddCourse, updateCourse } from '../Servies/AdmiServices';

const FileSelectionDialog = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle color={'Red'}>Validation Error</DialogTitle>
      <DialogContent>Please select All the value before choosing a file.</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default function EditStudent() {
  const [studentName, setStudentName] = useState('');
  const [div, setDiv] = useState('');
  const [grade, setGrade] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const fileInputRef = useRef(null);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [studentData, setStudentData] = useState({});
  const params = new URLSearchParams(window.location.search);
  const userId = params.get('userId');
  console.log('check', userId);

  const [formData, setFormData] = useState({
    studentName: '',
    grade: '',
    div: '',
  });

  const [initialStudentData, setInitialStudentData] = useState({
    studentName: {
      value: studentName,
      error: '',
      check: [],
    },
    grade: {
      value: grade,
      error: '',
      check: [],
    },
    div: {
      value: div,
      error: '',
      check: [],
    },
  });
  const emptyProxyObject = new Proxy({}, {});
  const isEmpty = (val) => {
    if (val === emptyProxyObject) return true;
    if (val === undefined) return true;

    if (
      typeof val === 'function' ||
      typeof val === 'number' ||
      typeof val === 'boolean' ||
      Object.prototype.toString.call(val) === '[object Date]'
    )
      return false;

    if (val === null || val.length === 0)
      // null or 0 length array
      return true;

    if (typeof val === 'object') if (Object?.keys(val)?.length === 0) return true;

    return false;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, '').slice(0, 10);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
      // [name.clientNumber]: numericValue,
    }));
  };

  useEffect(() => {
    if (userId) {
      editStudents(userId)
        .then((response) => {
          setStudentData(response.data);
          console.log('checking', response.data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  const handleChangeStudentName = (e) => {
    const copyArray = { ...initialStudentData };
    copyArray.studentName.value = e.target.value;
    setInitialStudentData(copyArray);
  };

  const handleChangeDivname = (e) => {
    const copyArray = { ...initialStudentData };
    copyArray.div.value = e.target.value;
    setInitialStudentData(copyArray);
  };

  const handleChangeGradename = (e) => {
    const copyArray = { ...initialStudentData };
    copyArray.grade.value = e.target.value;
    setInitialStudentData(copyArray);
  };

  const handlepage = () => {
    navigate(-1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      !isEmpty(initialStudentData.studentName.value) ||
      !isEmpty(initialStudentData.div.value) ||
      !isEmpty(initialStudentData.grade.value) ||
      initialStudentData.studentName.value !== studentData.studentName ||
      initialStudentData.div.value !== studentData.div ||
      initialStudentData.grade.value !== studentData.grade
    ) {
      submitEditData(
        userId,
        initialStudentData.studentName.value,
        initialStudentData.grade.value,
        initialStudentData.div.value
      )
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          navigate('/dashboard/studentList');
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    const copyArray = { ...initialStudentData };
    copyArray.studentName.value = studentData.studentName;
    copyArray.div.value = studentData.div;
    copyArray.grade.value = studentData.grade;
    setInitialStudentData(copyArray);
  }, [studentData]);
  return (
    <>
      {/* {isLoading && <Loader />} */}
      <ToastContainer />
      <Helmet>
        <title>Create Course</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            School Admin Platform – Students – Edit Info
          </Typography>
        </Stack>
        <Card sx={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
          <CardContent>
            <form style={{ display: 'flex', flexDirection: 'column', gap: '20px' }} onSubmit={handleSubmit}>
              <TextField
                name="name"
                label="Student Name"
                variant="outlined"
                value={initialStudentData.studentName.value}
                onChange={handleChangeStudentName}
              />

              <TextField
                name="div"
                label="Division"
                variant="outlined"
                value={initialStudentData.div.value}
                onChange={handleChangeDivname}
              />

              <TextField
                name="grade"
                label="Grade"
                variant="outlined"
                value={initialStudentData.grade.value}
                onChange={handleChangeGradename}
              />

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ width: '150px', alignSelf: 'center', marginLeft: '1rem' }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>

                <Button
                  type="button"
                  variant="contained"
                  sx={{
                    width: '120px',
                    textAlign: 'center',
                    marginLeft: '2rem',
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                  onClick={handlepage}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
        <FileSelectionDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
      </Container>
    </>
  );
}
