// ALL API END POINT
export const LOGIN_ENDPOINT = '/teacher/login';
export const REGISTRATION_ENDPOINT = '/teacher/registration';
export const GET_SCHOOL = 'api/master/getSchoolList';
export const GET_STUDENT_LIST = '/teacher/studentList';
export const EDIT_STUDENT = '/teacher/edit-student';
export const GET_REPORTS = '/report/tp-studentReport';
export const LEADER_BOARD = '/report/tp-leaderboard';
export const APPROVE_TEACHER_LIST = '/client/approvalList';
export const APPROVE_TEACHER = '/client/teacherApprove';
export const DELETE_TEACHER = '/client/teacherDelete';
export const GET_ANALYTICS = '/report/tp-analytics';
export const SAVE_STUDENT_DETAILS = '/teacher/saveEditStudent';
export const REMOVE_SCHOOL_STUDENT = '/teacher/removeStudent';
export const GET_PROGRESS_REPORT = '/report/tp-synopsis';
export const PERSONAL_REPORT = '/report/tp-progressReport';
export const STUDENT_LISTDOWNLOAD = '/teacher/studentListDownload';
export const DOWNLOAD_USER_SUB_DETAIL = '/teacher/subjectListDownload';
export const GET_SUBJECT_DROPDOWN = '/api/master/getSubject';
