import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
// import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, CircularProgress, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components

import { Link, Container, Typography, TextField, Button, Card, CardContent, Grid, Paper } from '@mui/material';
// import LockIcon from '@mui/icons-material/Lock';
// import PersonIcon from '@mui/icons-material/Person';
import { login } from '../../../Api/auth';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [username, setUserName] = useState();

  // const { setAuthTokens, } = useAuth();
  const [password, setPassword] = useState();

  const handleClick = () => {
    login(username, password)
      .then((res) => {
        console.log(res?.data, res?.data?.message);
        if (res.status === 200) {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate('/dashboard', { replace: true });
        } else {
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {});
    // navigate('/dashboard', { replace: true });
  };
  const handlenext = () => {
    // login(username, password)
    //   // login(username, password)
    //   .then((res) => {
    //     console.log(res?.data, res?.data?.message)
    //     if (res.status === 200) {
    //       toast.success(res?.data?.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //       navigate('/dashboard', { replace: true });
    //     } else {
    //       toast.error("Invalid user name or password", {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     }
    //   })
    //   .catch((err) => {});
    navigate('/Register');
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh', margin: '0' }}>
        <Grid item md={6} style={{ width: '50%' }}>
          <Paper elevation={3} square style={{ minHeight: '400px', width: '85%', padding: '20px', margin: '0 98px' }}>
            <Container maxWidth="sm">
              <CardContent>
                <img src="/favicon/Chetana logo.png" height={55} width={100} alt="logo" />
                <img
                  src="/logo-02 (1).png"
                  height={50}
                  style={{ marginLeft: '100px', marginTop: '-45px' }}
                  alt="logo"
                />

                <br />

                <Typography variant="h4" align="center" gutterBottom>
                  Login
                </Typography>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Username"
                        autoComplete="username"
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Password"
                        type="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        autoComplete="current-password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button fullWidth variant="contained" color="primary" size="large" onClick={handleClick}>
                        Login
                      </Button>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Typography align="center">
                        <Link to="/forgot-password" style={{ textDecoration: 'none' }}>
                          Forgot password?
                        </Link>
                      </Typography>
                    </Grid> */}
                  </Grid>
                </form>
              </CardContent>
            </Container>
          </Paper>
        </Grid>
        {/* <Grid item md={6} style={{ width: '50%' }}>
        <Paper
          elevation={5}
          square
          style={{
            minHeight: '435px',
            width: '80%',
            padding: '30px',
            margin: '50px 20px',
            backgroundColor: 'blue',
            color: 'white',
          }}
        > */}

        <Grid item md={6} style={{ width: '50%' }}>
          <Paper
            elevation={5}
            square
            style={{ minHeight: '435px', padding: '30px', width: '80%', backgroundColor: 'blue', color: 'white' }}
          >
            <Container maxWidth="sm">
              <CardContent>
                <Typography
                  variant="h3"
                  align="center"
                  gutterBottom
                  sx={{ fontFamily: 'revert-layer', fontStyle: 'revert-layer' }}
                >
                  School Administration Portal
                </Typography>
                <Typography variant="body1" align="center" paragraph>
                  This platform provides access to Admin from registered schools to administer student performances.
                </Typography>
                <Typography align="center">
                  <Link to="/register" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="primary" size="large" onClick={handlenext}>
                      Register Now!
                    </Button>
                  </Link>
                </Typography>
              </CardContent>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
