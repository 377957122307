import React from 'react';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import { downloadStudentListExcel } from '../Servies/AdmiServices';
import Iconify from './iconify';

const BinaryToExcel = () => {
  const school = localStorage.getItem('schoolName');

  const downloadExcelFile = ({ binaryData }) => {
    console.log(binaryData, 'check binary');
    // Convert binary data to workbook
    const workbook = XLSX.read(binaryData, { type: 'arraybuffer' });

    // Create a Blob from the workbook
    const blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'blob' })], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Create a link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);

    // Set the file name
    link.download = 'your_excel_file.xlsx';

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger the click event to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  const handleDownload = async () => {
    try {
      const response = await downloadStudentListExcel(school);
      console.log(response, 'download hey');
      downloadExcelFile({ binaryData: response.arrayBuffer() });
      // const blob = new Blob([response], {
      //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // });
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // // a.download = 'StudentList.xls';
      // a.setAttribute('download', `${Date.now()}`); // Note: Change the file extension to .xlsx if the data is in XLSX format
      // document.body.appendChild(a);
      // // Trigger a click event to download the file
      // a.click();
      // // Clean up
      // window.URL.revokeObjectURL(url);
      // document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <Button
      variant="contained"
      startIcon={<Iconify icon="mdi:download-outline" />}
      sx={{ marginLeft: '700px' }}
      onClick={handleDownload}
    >
      Download
    </Button>
  );
};

export default BinaryToExcel;
