import React, { useEffect, useState } from 'react';
import {
  Container,
  Stack,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Box,
  Button,
} from '@mui/material';
import { DataGridPro, GridColDef, DataGrid, GridColumnGroupingModel } from '@mui/x-data-grid';
import { Helmet } from 'react-helmet-async';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Loader from '../components/common/Loader';

import Iconify from '../components/iconify';
import { getAnyalaticApi } from '../Servies/AdmiServices';

export default function Analytics() {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedendDate, setSelectedendDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date()); // State for the selected date
  const [analyticsdata, setAnylaticData] = useState([]);
  const schoolName = localStorage.getItem('schoolName');
  const [isLoading, setIsLoading] = useState(false);

  console.log(schoolName);

  const [rows, setRows] = useState([]);

  const handlesubmit = () => {
    setIsLoading(true);
    getAnyalaticApi(selectedDate, selectedendDate, schoolName)
      .then((res) => {
        if (res?.data && res.data.length > 0) {
          console.log(res.data);
          setAnylaticData(res.data);
          setRows(analyticsdata);
          setIsLoading(false);
        } else {
          // Handle empty response data, for example:
          console.log('No data available');
          // You can set a message or handle it in any other way appropriate for your application
        }
      })
      .catch((err) => {
        // Handle errors here
        setIsLoading(false);

        console.error('Error occurred:', err);
      });
  };

  const generateColumns = (gradeFields) => {
    return [
      { field: 'subject', headerName: 'Subject', width: 200 },
      ...gradeFields.flatMap((gradeField) => [
        {
          field: `${gradeField}.Regtd1`,
          headerName: `Regtd`,
          width: 120,
          valueGetter: (params) =>
            params.row.grades.find((grade) => grade.Grade === parseInt(gradeField.slice(-1), 10))?.Regtd1 || 0,
        },
        {
          field: `${gradeField}.Active1`,
          headerName: `Active`,
          width: 120,
          valueGetter: (params) =>
            params.row.grades.find((grade) => grade.Grade === parseInt(gradeField.slice(-1), 10))?.Active1 || 0,
        },
        {
          field: `${gradeField}.InActive`,
          headerName: `InActive`,
          width: 120,
          valueGetter: (params) =>
            params.row.grades.find((grade) => grade.Grade === parseInt(gradeField.slice(-1), 10))?.InActive || 0,
        },
      ]),
    ];
  };

  const generateColumnGroupingModel = (gradeFields) => {
    return gradeFields.map((gradeField) => ({
      groupId: gradeField,
      headerName: gradeField,
      children: ['Regtd1', 'Active1', 'InActive'].map((type) => ({
        field: `${gradeField}.${type}`,
      })),
    }));
  };
  const gradeFields = ['Grade1', 'Grade2', 'Grade3', 'Grade4'];
  const columns = generateColumns(gradeFields);
  const columnGroupingModel = generateColumnGroupingModel(gradeFields);
  useEffect(() => {
    // const fetchAndSetData = async () => {
    //   try {
    //     // const data = await fetchData();
    //     setRows(analyticsdata);
    //   } catch (error) {
    //     // Handle error here
    //     console.error('Error fetching data:', error);
    //   }
    // };
    // handlesubmit();
  }, []);
  console.log(rows, 'data1');
  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Perform any actions you want when the date is changed
  };
  return (
    <>
      {isLoading && <Loader />}

      <Helmet>
        <title>Analytics</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            School Admin Platform - Analytics
          </Typography>
        </Stack>

        <Box display="flex" justifyContent="space-between" marginBottom={2}>
          <Stack spacing={2} direction="row" paddingLeft={2}>
            <TextField
              label="From Date"
              type="date"
              value={selectedDate.toISOString().split('T')[0]} // Format date as "YYYY-MM-DD"
              onChange={(e) => handleDateChange(new Date(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              label="To Date"
              type="date"
              value={selectedendDate.toISOString().split('T')[0]} // Format date as "YYYY-MM-DD"
              onChange={(e) => setSelectedendDate(new Date(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              variant="contained"
              startIcon={<Iconify icon="formkit:submit" />}
              sx={{ marginLeft: '899px', height: '50px' }}
              onClick={handlesubmit}
            >
              Submit
            </Button>
          </Stack>
        </Box>
        {analyticsdata.length > 0 && (
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              experimentalFeatures={{ columnGrouping: true }}
              rows={analyticsdata}
              columns={columns}
              // checkboxSelection
              disableRowSelectionOnClick
              columnGroupingModel={columnGroupingModel}
            />
          </div>
        )}
      </Container>
    </>
  );
}
