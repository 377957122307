import { Helmet } from 'react-helmet-async';

import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  alertTitleClasses,
  FormControl,
  Grid,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
// import JSZip from 'jszip';
// import XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaClipboardList, FaTrash } from 'react-icons/fa';
import { IconName } from 'react-icons/ai';
import BinaryToExcel from '../components/binaryToExcel';

import {
  getStudentLists,
  editStudents,
  removeSchool,
  getStudentFilters,
  downloadStudentList,
  downloadStudentListExcel,
} from '../Servies/AdmiServices';

import Iconify from '../components/iconify';

import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';

export default function StudentsList() {
  const navigate = useNavigate();
  const school = localStorage.getItem('schoolName');
  console.log(school);
  const [search, setSearch] = useState('');

  const [student, setStudents] = useState([]);
  const headers = ['studentName', 'grade', 'div', 'Book Registered', 'Book Name', 'lastActive', 'Action'];
  const [showTable, setShowTable] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [downloadData, setDownloadData] = useState();

  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [grade, setGrade] = useState('');
  const [div, setDiv] = useState();

  // Default
  const handleChange = (event) => {
    setGrade(event.target.value);
  };
  const handleChangeDiv = (event) => {
    setDiv(event.target.value);
  };
  const handlenameChange = (event) => {
    setSearch(event.target.value);
  };
  const handleEditEdit = (userId) => {
    navigate(`/dashboard/edit?userId=${userId}`);
  };

  const fetchData = () => {
    setIsLoading(true);
    getStudentLists(school)
      .then((response) => {
        setShowTable(response.data);
        console.log('check', response.data);
        setIsLoading(false);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
      });
  };

  console.log(showTable);
  useEffect(() => {
    fetchData();
  }, [school]);

  // const handleNextPage = () => {
  //   const newPageSize = pageSize + 10;
  //   setPageSize(newPageSize);
  //   fetchData(1, pageSize);
  // };

  // const handlePrevPage = () => {
  //   if (pageNo > 1) {
  //     setPageNo(pageNo - 1);
  //     fetchData();
  //   }
  // };

  // const handleDownload = async () => {
  //   try {
  //     const res = await DownloadStudentList(school);
  //     const data = res.data; // Assuming the response contains data property with the Excel data

  //     // Convert the response data to Excel format
  //     const ws = XLSX.utils.json_to_sheet(data);
  //     const wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, 'StudentList');
  //     console.log(data, 'data');
  //     // Create Excel Blob
  //     const excelBlob = XLSX.write(wb, { bookType: 'xlsx', type: 'blob' });

  //     // Create download link and trigger download
  //     const downloadLink = document.createElement('a');
  //     downloadLink.href = window.URL.createObjectURL(excelBlob);
  //     downloadLink.download = 'StudentList.xlsx';
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();
  //   } catch (err) {
  //     console.error('Error downloading file:', err);
  //   }
  // };

  // const handleDownload = async () => {
  //   DownloadStudentList(school)
  //     .then((res) => {
  //       console.log('res', res.data);
  //       const blob = new Blob([res.data], {
  //         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //       });
  //       const a = document.createElement('a');
  //       a.href = window.URL.createObjectURL(blob);
  //       document.body.appendChild(a);
  //       a.style = 'display: none';
  //       a.href = res.data;
  //       a.download = 'StudentList.xls';
  //       a.click();
  //       window.URL.revokeObjectURL(a);
  //       a.parentNode.removeChild(a);
  //       const zip = new JSZip();
  //       zip.folder(a);
  //     })
  //     .catch((error) => {});
  // };

  // const handleDownload = async () => {
  //   try {
  //     const response = await DownloadStudentList(school);
  //     const blob = new Blob([response.data], {
  //       type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //     });

  //     const url = window.URL.createObjectURL(blob);

  //     // Create a download link
  //     const a = document.createElement('a');
  //     a.href = url;
  //     // a.download = 'StudentList.xls';
  //     a.setAttribute('download', `${Date.now()}.xlsx`); // Note: Change the file extension to .xlsx if the data is in XLSX format
  //     document.body.appendChild(a);

  //     // Trigger a click event to download the file
  //     a.click();

  //     // Clean up
  //     window.URL.revokeObjectURL(url);
  //     document.body.removeChild(a);
  //   } catch (error) {
  //     console.error('Error downloading file:', error);
  //   }
  // };

  console.log('downloadData', downloadData);

  const handleSerach = () => {
    setIsLoading(true);
    getStudentFilters(school, search, grade, div)
      .then((response) => {
        setShowTable(response.data);
        console.log('check', response.data);
        // setIsLoading(false);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
      });
  };
  const handleDeleteStudent = (userId) => {
    setIsLoading(true);
    removeSchool(userId)
      .then((res) => {
        console.log('check delete', res?.data);
        toast.success(res?.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error('Error fetching course data:', error);
      });
  };

  const specificColumns = Array.isArray(showTable)
    ? showTable.map((item) => {
        console.log('div', item, item?.grade);
        return {
          studentName: item.studentName,
          grade: item?.grade,
          div: item.div,
          'Book Registered': item.bookRegistered,
          'Book Name': item.bookName,

          lastActive: item.lastActive,
          Action: (
            <div>
              <FaEdit
                style={{ cursor: 'pointer', marginRight: '10px' }}
                onClick={() => handleEditEdit(item.userId)} // Pass the user ID to the edit handler
              />
              <FaTrash
                style={{ cursor: 'pointer', marginLeft: '90px', margin: '2px' }}
                onClick={() => handleDeleteStudent(item.id)}
                // onClick={() => handleDeleteUser(item.userId)}
              />
            </div>
          ),
        };
      })
    : [];

  return (
    <>
      {isLoading && <Loader />}

      {/* {showLoader && <Loader />} */}
      <ToastContainer />
      <Helmet>
        <title> Client </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            School Admin Platform - Students
          </Typography>
        </Stack>
        <div>
          <TextField
            name="Name"
            label="Name"
            style={{ width: '260px', marginRight: '10px' }}
            onChange={handlenameChange}
          />
          <FormControl sx={{ marginRight: '10px', minWidth: 80 }}>
            <InputLabel id="demo-simple-select-autowidth-label">Grade</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={grade}
              onChange={handleChange}
              autoWidth
              label="Grade"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ marginRight: '10px', minWidth: 80 }}>
            <InputLabel id="demo-simple-select-autowidth-label">Div</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={div}
              onChange={handleChangeDiv}
              autoWidth
              label="Div"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'A'}>A</MenuItem>
              <MenuItem value={'B'}>B</MenuItem>
              <MenuItem value={'C'}>C</MenuItem>
              <MenuItem value={'D'}>D</MenuItem>
              <MenuItem value={'E'}>E</MenuItem>
            </Select>
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            startIcon={<Iconify icon="material-symbols:search" />}
            color="primary"
            style={{ height: '55px' }}
            onClick={handleSerach}
          >
            Search
          </Button>
        </div>
        <br />
        <TableData data={specificColumns} headers={headers} rowsPerPageOptions={[5, 10, 20]} />
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
          <Button variant="contained" color="primary" onClick={handlePrevPage}>
            Previous
          </Button>
          <Button variant="contained" color="primary" onClick={handleNextPage}>
            Next
          </Button>
        </div> */}
        <br />
        <BinaryToExcel />
      </Container>
    </>
  );
}
