import React, { useEffect, useState } from 'react';
import {
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Loader from '../components/common/Loader';

import Iconify from '../components/iconify';

import AppTeachersSummary from '../sections/@dashboard/app/AppTeachersSummary';
import { getReportsApi, getSubjectDropdown } from '../Servies/AdmiServices';

export default function Report() {
  const [startDate, setStartDate] = useState(new Date()); // State for the selected date
  const [endDate, setEndDate] = useState(new Date());
  const [reportdata, setReportData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [grade, setGrade] = useState('');
  const [search, setSearch] = useState('');
  const [subject, setSubject] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const schoolName = localStorage.getItem('schoolName');
  const handleDateChange = (date) => {
    setStartDate(date);
    // Perform any actions you want when the date is changed
  };

  const handlegradeChange = (event) => {
    setGrade(event.target.value);
  };

  const handlenameChange = (event) => {
    setSearch(event.target.value);
  };

  const onSelectSubject = (event) => {
    // const selectedSubjectObject = subject.find((subject) => subject.subjectName === event.target.value);
    setSelectedSubject(event.target.value);

    console.log(event.target.value);
  };

  const handlesubmit = () => {
    const data = {
      startDate,
      endDate,
      schoolName,
      grade,
      subjectId: selectedSubject ? selectedSubject.levelId : '',
    };
    setIsLoading(true);
    getReportsApi(data)
      .then((response) => {
        setReportData(response?.data);
        console.log('hello', setReportData);
        setIsLoading(false);
        // toast.success(res?.data?.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        // navigate('/dashboard', { replace: true });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
      });
  };
  console.log('hello', reportdata);

  const getSubjectList = async () => {
    try {
      const response = await getSubjectDropdown();
      const subjectCode = response?.data?.map((data) => ({
        subjectName: data?.name,
        levelId: data?.id,
      }));

      setSubject(subjectCode);
      console.log(subjectCode, 'check subject');
    } catch (error) {
      console.error('Error fetching subject details:', error);
    }
  };
  useEffect(() => {
    getSubjectList();
  }, []);

  return (
    <>
      {isLoading && <Loader />}

      <Helmet>
        <title>Report</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            School Admin Platform - Dashboard
          </Typography>
        </Stack>
        <Stack spacing={2} direction="row" paddingLeft={2}>
          <TextField
            label="From Date"
            type="date"
            value={startDate.toISOString().split('T')[0]} // Format date as "YYYY-MM-DD"
            onChange={(e) => handleDateChange(new Date(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            label="To Date"
            type="date"
            value={endDate.toISOString().split('T')[0]} // Format date as "YYYY-MM-DD"
            onChange={(e) => setEndDate(new Date(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl sx={{ marginRight: '10px', minWidth: 80 }}>
            <InputLabel id="demo-simple-select-autowidth-label">Grade</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={grade}
              onChange={handlegradeChange}
              autoWidth
              label="Grade"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel id="demo-simple-select-autowidth-label">Subject Name</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={selectedSubject}
              onChange={(e) => {
                onSelectSubject(e);
              }}
              input={<OutlinedInput label="Vehicle Model" />}
              startAdornment={
                <InputAdornment position="start">
                  {isLoading === true ? <CircularProgress size={15} /> : ''}
                </InputAdornment>
              }
              MenuProps={MenuProps}
            >
              {subject.map((subject) => (
                <MenuItem key={subject.levelId} value={subject}>
                  {subject.subjectName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            startIcon={<Iconify icon="formkit:submit" />}
            sx={{ marginLeft: '899px', height: '50px' }}
            onClick={handlesubmit}
          >
            Submit
          </Button>
        </Stack>
        <br />

        <Stack direction="row" spacing={3}>
          {/* <Card sx={{ width: 200 }}>
            <AppTeachersSummary title="Teachers Registered" total={reportdata?.teacherRegistered || 0} />
          </Card> */}
          <Card sx={{ width: 200 }}>
            <AppTeachersSummary title="Student Registered" color="info" total={reportdata?.studentRegistered || 0} />
          </Card>
          <Card sx={{ width: 200 }}>
            <AppTeachersSummary title="Student Active" color="info" total={reportdata?.studentActive || 0} />
          </Card>
          <Card sx={{ width: 200 }}>
            <AppTeachersSummary title="Most Active" color="success" total={reportdata?.mostActive || 0} />
          </Card>
          <Card sx={{ width: 200 }}>
            <AppTeachersSummary title="Most InActive" total={reportdata?.mostInactive || 0} color="error" />
          </Card>
        </Stack>
      </Container>
    </>
  );
}
