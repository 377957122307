import React, { useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';

import DashboardAppPage from './pages/DashboardAppPage';

import RegistrationPage from './pages/RegistrationPage';
import StudentsList from './pages/StudentsList';
import EditStudent from './pages/EditStudent';
import TeachersApproval from './pages/TeachersApproval';
// import ApprovalTeacher from './pages/ApprovalTeacher';
import Leaderboard from './pages/Leaderboard';
import Report from './pages/Report';
import Analytics from './pages/Analytics';
import PersonalAnalytic from './pages/PersonalAnalytic';
import ManageLeader from './pages/ManageLeader';

// import AddSchoolAdmin from './pages/AddSchoolAdmin';

export default function Router() {
  const [authenticated, setAuthenticated] = useState(false);

  const handleLogin = () => {
    setAuthenticated(true);
  };

  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/login" />, // Redirect to the login page by default
    },
    {
      path: '/login',
      element: authenticated ? <Navigate to="/dashboard" /> : <LoginPage onLogin={handleLogin} />,
      index: true, // Set this as the default page
    },
    {
      path: '/register',
      element: authenticated ? <Navigate to="/dashboard" /> : <RegistrationPage />,
      index: true,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/studentList" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'report', element: <Report /> },
        { path: 'studentList', element: <StudentsList /> },
        { path: 'edit', element: <EditStudent /> },
        { path: 'approval', element: <TeachersApproval /> },
        { path: 'leaderboard', element: <Leaderboard /> },
        { path: 'analytics', element: <Analytics /> },
        { path: 'personal', element: <PersonalAnalytic /> },

        { path: 'manageleader', element: <ManageLeader /> },
        //   <ProgressReportProvider>
        //   <ManageLeader />
        // </ProgressReportProvider> // { path: 'addschooldmin', element: <AddSchoolAdmin /> },
      ],
    },
    {
      path: '/404',
      element: <SimpleLayout />,
      children: [{ element: <Page404 /> }],
    },
    {
      path: '*',
      element: <Navigate to="/404" />,
    },
  ]);

  return routes;
}
