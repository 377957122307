import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Stack,
  Link,
  Button,
} from '@mui/material';
// components
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import Loader from '../components/common/Loader';

import { getPersonalReport, getStudentReport, getExcel } from '../Servies/AdmiServices';

// import Iconify from '../components/iconify';
// // sections
// import {
//   AppTasks,
//   AppNewsUpdate,
//   AppOrderTimeline,
//   AppCurrentVisits,
//   AppWebsiteVisits,
//   AppTrafficBySite,
//   AppWidgetSummary,
//   AppCurrentSubject,
//   AppConversionRates,
// } from '../sections/@dashboard/app';
import TableData from '../components/common/TableData';
// import { useReportContext } from '../hooks/ReportContext';
import Iconify from '../components/iconify';

export default function PersonalAnalytic(props) {
  const theme = useTheme();
  const headers = ['Chapters', 'Topic Viewed', 'Activity Check', ' Total Assessed(MCQ) Score'];
  const [personalReport, setPersnalReport] = useState([]);
  const [progressReport, setProgressReport] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const userId = new URLSearchParams(location.search).get('userId');
  const courseId = new URLSearchParams(location.search).get('courseId');

  const subjectId = new URLSearchParams(location.search).get('subjectId');
  const schoolName = localStorage.getItem('schoolName');

  // console.log(subjectname, 'name');
  useEffect(() => {
    if (userId) {
      setIsLoading(true);

      getPersonalReport(userId, subjectId, schoolName)
        .then((response) => {
          setPersnalReport(response.data);
          setIsLoading(false);

          // setChecked(response.data?.Data?.isActive);
        })
        .catch((error) => {
          setIsLoading(false);

          console.error('Error fetching user data:', error);
        });
    }
  }, [userId]);
  console.log('max', personalReport);

  useEffect(() => {
    if (userId) {
      getStudentReport(userId, courseId)
        .then((response) => {
          setProgressReport(response.data);
          // response?.data.map((item) => {
          //   if (item.subjectId === subjectId) {
          //   }
          //   console.log('check subject', item?.name);
          // });
          // setSubjectsvalues(response?.data?.subjects);
          // setChecked(response.data?.Data?.isActive);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [userId, courseId]);
  console.log('hello kunal', progressReport);

  // const handleDownload = () => {
  //   setIsLoading(true);

  //   getExcel(userId, subjectId, schoolName)
  //     .then((res) => {
  //       if (res?.data?.status === 'SUCCESS') {
  //         setIsLoading(false);
  //         toast.success(res?.data?.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       } else {
  //         toast.error(res?.data?.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((error) => {});
  // };

  const handleDownload = async () => {
    try {
      const res = await getExcel(userId, subjectId, schoolName);
      const jsonData = res?.data;
      console.log(jsonData, 'hffff');

      if (!jsonData) {
        console.error('Response data is empty.');
        return;
      }

      const fileName = '1700653823496subject_list.xlsx';
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(jsonData), 'Data');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
      const blob = new Blob([s2ab(excelBuffer)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, fileName);
    } catch (err) {
      console.error('Error downloading file:', err);
    }
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i += 1) {
      view[i] = s.charCodeAt(i) % 256;
    }
    return buf;
  }

  const students = Array.isArray(personalReport)
    ? personalReport.map((item) => {
        return {
          Chapters: item?.chapters,
          'Topic Viewed': item.videoViews,
          ' Total Assessed(MCQ)': item.assessed,
          'Activity Check': item.activityCheck,
        };
      })
    : [];
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            School Admin Platform – Progress Report – {progressReport?.name}
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          {/* Student Image */}
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ maxWidth: 250, height: '100%' }}>
              <CardActionArea sx={{ height: '100%' }}>
                {progressReport?.imageUrl === 'null' || progressReport?.imageUrl === ' ' ? (
                  <img src="/favicon/image_processing20200424-23023-niqwy1.png" height={200} alt="green iguana" />
                ) : (
                  <img height={250} width={250} src={progressReport?.imageUrl} alt="green iguana" />
                )}
              </CardActionArea>
            </Card>
          </Grid>

          {/* Student Details */}
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ maxWidth: 250, height: '100%' }}>
              <CardActionArea sx={{ height: '100%' }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {progressReport?.name}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Grade {progressReport?.grade}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Division {progressReport?.division}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    points {progressReport?.points}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Rank {progressReport?.rank}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          {/* Student Subject */}
        </Grid>
        <br />
        {/* <Grid>
          <h1>{subjectname}</h1>
        </Grid> */}
        <TableData data={students} headers={headers} rowsPerPageOptions={[5, 10, 20]} />
        <br />
        <div>
          <Button
            variant="contained"
            startIcon={<Iconify icon="mdi:download-outline" />}
            sx={{ marginLeft: '700px' }}
            onClick={handleDownload}
          >
            Download
          </Button>{' '}
        </div>
        {/* <div>
          <h3>
            Show details for: <Link color="primary">Grammer</Link> ,<Link color="primary">Drawing</Link> ,
            <Link color="primary">Hand writing</Link> ,<Link color="primary">Computer Science</Link>{' '}
          </h3>
        </div> */}
      </Container>
    </>
  );
}
