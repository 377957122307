import axios from 'axios';
import { TOKEN } from '../config/localstorage_keys';
import { getLocalStorage, setLocalStorage } from '../utils/strorage';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 1000 * 120, // Wait for 10 seconds
  headers: {
    'Content-Type': 'application/json',
  },

  validateStatus: (status) => {
    return true;
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = getLocalStorage(TOKEN);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['x-access-token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    if (res?.status === 401) {
      localStorage.removeItem('token');
      setTimeout(() => {
        window.location.replace('/login');
      }, 500);
    } else if (res?.headers?.token) {
      setLocalStorage(TOKEN, res?.headers?.token);
    }
    return res;
  },
  async (err) => {}
);

export default instance;
