import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Stack,
  Button,
  Tooltip,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import Avatar from 'react-avatar';

import Loader from '../components/common/Loader';
// import ImgUrls from '../../public/User-Avatar-PNG-Transparent-Image.png';

import { getStudentReport } from '../Servies/AdmiServices';
// import profileImg from '../User-Avatar-PNG-Transparent-Image.png';

import Iconify from '../components/iconify';
// import { useReportContext } from '../hooks/ReportContext';

// import Iconify from '../components/iconify';
// import {
//   AppTasks,
//   AppNewsUpdate,
//   AppOrderTimeline,
//   AppCurrentVisits,
//   AppWebsiteVisits,
//   AppTrafficBySite,
//   AppWidgetSummary,
//   AppCurrentSubject,
//   AppConversionRates,
// } from '../sections/@dashboard/app';

const dummyApiResponse = [
  {
    id: 1,
    name: 'Faye D’Souza',
    division: 'A',
    grade: 6.9,
    points: 236,
    rank: 'A++',
    subjects: [
      { name: 'General Knowledge1', value: 30, subjectid: 1 },
      { name: 'Grammar', value: 25, subjectid: 2 },
      { name: 'Computer Science', value: 35, subjectid: 3 },
      { name: 'Drawing', value: 28, subjectid: 4 },
      { name: 'Hindi', value: 30, subjectid: 5 },
    ],
    interests:
      ' Faye shows an acute interest in seeking knowledge and self-learning. Encouraged to help her with topic variety. Recommendations: Topics why binary, evolution of the computer',
    imageUrl: '/User-Avatar-PNG-Transparent-Image.png',
  },
];

const ManageLeader = () => {
  const pdfRef = useRef();
  const [progressReports, setProgressReports] = useState();
  const [subjectsvalues, setSubjectsvalues] = useState([]);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [subjectname, setSubjectName] = useState();

  // const [chartData, setChartData= useState();

  const userId = new URLSearchParams(location.search).get('userId');
  const courseId = new URLSearchParams(location.search).get('courseId');

  useEffect(() => {
    if (userId) {
      setIsLoading(true);

      getStudentReport(userId, courseId)
        .then((response) => {
          setProgressReports(response.data);
          setSubjectsvalues(response?.data?.subjects);
          setIsLoading(false);

          setIsLoading(false);

          // setChecked(response.data?.Data?.isActive);
        })
        .catch((error) => {
          setIsLoading(false);

          console.error('Error fetching user data:', error);
        });
    }
  }, [userId, courseId]);
  console.log('hello kunal', subjectsvalues);

  const chartData =
    // progressReport?.subjects.length > 0 &&
    subjectsvalues.map((subject) => ({
      label: subject.name,
      value: subject.value,
      subjectId: subject.subjectId,
    }));

  const subjectData =
    // progressReport?.subjects.length > 0 &&
    subjectsvalues.map((subject) => ({
      label: subject.name,
      value: subject.value,
      subjectId: subject.subjectId,
    }));

  // useEffect(() => {
  //   if (progressReport?.length > 0) {
  //     setChartData(
  //       progressReport?.subjects.map((subject) => ({
  //         label: subject.name,
  //         value: subject.value,
  //       }))
  //     );
  //   }
  // }, [progressReport]);
  console.log('mini', subjectsvalues, chartData);
  // const { progressReport } = useProgressReport();
  const theme = useTheme();
  const navigate = useNavigate();
  const options = {
    chart: {
      type: 'donut',
      height: 350,
      events: {
        dataPointSelection(event, chartContext, config) {
          if (options.labels && options.labels.length > 0) {
            const dataPointIndex = config.dataPointIndex;
            const clickedSubject = options.labels[dataPointIndex];
            const subid = chartData?.length > 0 && chartData?.map((item) => item.subjectId);
            const subjectid = subid[dataPointIndex];
            const subjectName = chartData?.length > 0 && chartData?.map((item) => item.subjectName)[dataPointIndex];
            console.log('Data Point Index:', dataPointIndex, 'Clicked Subject:', clickedSubject, subid, subjectid);
            navigate(`/dashboard/personal?userId=${userId}&subjectId=${subjectid}&courseId=${courseId}`);
          }
        },
      },
    },
    labels: chartData?.length > 0 && chartData?.map((item) => item.label),

    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
        donut: {
          size: '75%',
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
  const handleNewUserClick = (userId, subjectId) => {
    navigate(`/dashboard/personal?userId=${userId}?subjectId=${subjectId}`);
  };

  const handleSubjectClick = (userId, subjectid, courseId) => {
    navigate(`/dashboard/personal?userId=${userId}&subjectId=${subjectid}&courseId=${courseId}`);
  };
  const handleChartClick = (event, chartContext, config) => {
    // Handle your click event logic here
    console.log('Chart clicked!', event, chartContext, config);
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new JsPDF('p', 'mm', 'a4', true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save('report.pdf');
    });
  };

  console.log(subjectData);

  return (
    <>
      {isLoading && <Loader />}

      <Container maxWidth="xl" ref={pdfRef}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            School Admin Platform – Progress Report – {progressReports?.name}
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ maxWidth: 250, height: '100%' }}>
              <CardActionArea sx={{ height: '100%' }}>
                {progressReports?.imageUrl === 'null' || progressReports?.imageUrl === ' ' ? (
                  <img src="/favicon/image_processing20200424-23023-niqwy1.png" height={200} alt="logoss" />
                ) : (
                  <img src={progressReports?.imageUrl} height={250} width={250} alt="logo" />
                )}
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ maxWidth: 250, height: '100%' }}>
              <CardActionArea sx={{ height: '100%' }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" onClick={handleNewUserClick}>
                    {progressReports?.name}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Division: {progressReports?.division}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Grade: {progressReports?.grade}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Points: {progressReports?.points}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Rank: {progressReports?.rank}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ height: '100%' }}>
              <CardActionArea sx={{ height: '100%' }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Synopsis
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {progressReports?.interests}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ height: '100%' }}>
              <ReactApexChart
                options={options}
                series={chartData?.map((item) => item.value)}
                type="donut"
                height={350}
              />
              {/* </CardActionArea> */}
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ height: '100%' }}>
              <CardActionArea sx={{ height: '100%' }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    View Subject Report
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {/* {subjectData?.map((item) => item.label)} */}
                    <ul>
                      {/* Use map to create list items */}

                      {subjectData.map((item, index) => (
                        <li key={index}>
                          <Tooltip title="View student's subject Report">
                            <a
                              href={`/dashboard/personal?userId=${userId}&subjectId=${item.subjectId}&courseId=${courseId}`}
                            >
                              {' '}
                              {item.label}
                            </a>
                          </Tooltip>
                        </li>
                      ))}
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography gutterBottom variant="h5" component="div">
              Note: Allern platform has a unique assessment mechanism designed particularly for grades 1 to 5 to create
              a learning and understanding path for them in supplementary skillsets. We provide a personalized learning
              development program to the child using traditional printed books with content that is curated per the
              grade. The platform then combines this with a digital measurement tool that can help identify the
              strengths and interest areas of a child, and in turn increase the pace of development of a child.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Button
        variant="contained"
        startIcon={<Iconify icon="mdi:download-outline" />}
        sx={{ marginLeft: '700px' }}
        onClick={downloadPDF}
      >
        Download
      </Button>
      {/* <PersonalAnalytic progressReport={progressReport} /> */}
    </>
  );
};

export default ManageLeader;
